<template>
  <div>
    <p class="h3">Uploaded Giftcard</p>

    <div class="card-body">
      <div v-if="!pageloading" class="card-deck no-gutters mb-4 mt-2 ">
        <div class="card shadow">
          <div class="card-body px-1">
            <div class="">
              <p class="mb-0 text-center">
                All GiftCards <br />
                {{ allGiftCards.length }}
              </p>
            </div>
          </div>
        </div>
        <div class="card shadow">
          <div class="card-body px-1">
            <div class="">
              <p class="mb-0 text-center">
                Pending <br />{{ pendingGiftCards.length }}
              </p>
            </div>
          </div>
        </div>
        <div class="card shadow">
          <div class="card-body px-1">
            <div class="">
              <p class="mb-0 text-center">
                Completed <br />
                {{ completedGiftCards.length }}
              </p>
            </div>
          </div>
        </div>
        <div class="card shadow">
          <div class="card-body px-1">
            <div class="">
              <p class="mb-0 text-center">
                Cancelled <br />{{ cancelledGiftCards.length }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!pageloading">
      <ul
        class="nav nav-pills justify-content-center my-5 py-5"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-all"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            >ALL</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-pending"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            >PENDING</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="pills-profile-tab"
            data-toggle="pill"
            href="#pills-completed"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            >COMPLETED</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="pills-contact-tab"
            data-toggle="pill"
            href="#pills-cancelled"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
            >CANCELLED</a
          >
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-all"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="row">
            <div
              class="col-md-4 col-xl-3"
              v-for="(item, index) of allGiftCards"
              :key="index"
            >
              <UsersGiftCardCard
                :item="item"
                v-on:viewGiftCard="viewGiftCard"
              />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade "
          id="pills-pending"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="row">
            <div
              class="col-md-4 col-xl-3"
              v-for="(item, index) of pendingGiftCards"
              :key="index"
            >
              <UsersGiftCardCard
                :item="item"
                v-on:viewGiftCard="viewGiftCard"
              />
            </div>
          </div>
          <p
            v-if="pendingGiftCards.length == 0"
            class="h3 font-weight-normal text-danger text-center"
          >
            There's No Pending GiftCard
          </p>
        </div>
        <div
          class="tab-pane fade"
          id="pills-completed"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div class="row">
            <div
              class="col-md-4 col-xl-3"
              v-for="(item, index) of completedGiftCards"
              :key="index"
            >
              <UsersGiftCardCard
                :item="item"
                v-on:viewGiftCard="viewGiftCard"
              />
            </div>
          </div>

          <div v-if="completedGiftCards.length == 0">
            <div class="row align-items-center justify-content-center py-3">
              <div class="card col-md-6 border-0 align-items-center">
                <img
                  class="center "
                  width="250"
                  height="250"
                  src="img/giftcard/no_giftcard.jpg"
                  alt=""
                />
                <br />
                <p class="h3 font-weight-normal text-danger text-center">
                  There Are Completed GiftCard
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-cancelled"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          <div class="row">
            <div
              class="col-md-4 col-xl-3"
              v-for="(item, index) of cancelledGiftCards"
              :key="index"
            >
              <UsersGiftCardCard
                :item="item"
                v-on:viewGiftCard="viewGiftCard"
              />
            </div>
          </div>

          <div v-if="!pageloading && cancelledGiftCards.length <= 0">
            <div class="row align-items-center justify-content-center py-3">
              <div class="card col-md-6 border-0 align-items-center">
                <img
                  class="center "
                  width="250"
                  height="250"
                  src="img/giftcard/no_giftcard.jpg"
                  alt=""
                />
                <br />
                <p class="h3 font-weight-normal text-danger text-center">
                  There's No Cancelled Giftcard
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else />

    <div v-if="!pageloading && allGiftCards.length <= 0">
      <div class="row align-items-center justify-content-center py-3">
        <div class="card col-md-6 border-0 align-items-center">
          <img
            class="center "
            width="250"
            height="250"
            src="img/giftcard/no_giftcard.jpg"
            alt=""
          />
          <br />
          <p class="h3 font-weight-normal text-danger text-center">
            There're No Uploaded Giftcard
          </p>
        </div>
      </div>
    </div>

    <!-- Button trigger modal -->
    <Modal v-if="showModal" :currentGiftCard="currentGiftCard" />
  </div>
</template>

<script>
import axios from "axios";
import Calls from "@/Calls";
import Spinner from "@/components/Spinner";
import Modal from "./components/Modal.vue";
import UsersGiftCardCard from "./components/UsersGiftCardCard.vue";

export default {
  name: "UsersUploaded",
  components: { Spinner, Modal, UsersGiftCardCard },
  data() {
    return {
      allGiftCards: [],
      pendingGiftCards: [],
      completedGiftCards: [],
      cancelledGiftCards: [],
      currentEdit: {},
      currentGiftCard: {},
      showModal: false,
      pageloading: true,
      loading: false,
    };
  },
  methods: {
    async viewGiftCard(val) {
      this.currentGiftCard = val;
      this.showModal = true;
    },
    async fetchGiftCards() {
      await axios
        .get(`${Calls.baseUrl()}/admin/giftcard`, {
          headers: JSON.parse(localStorage["headers"]),
        })
        .then((res) => {
          this.allGiftCards = res.data.data.reverse();
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.msg,
            type: "error",
            position: "top-right",
            duration: 2000,
            pauseOnHover: true,
          });
        });
    },
  },
  async created() {
    await this.fetchGiftCards();

    this.allGiftCards.forEach((item) => {
      if (item.status == "PENDING") {
        this.pendingGiftCards.push(item);
        return;
      }
      if (item.status == "CANCELLED") {
        this.cancelledGiftCards.push(item);
        return;
      }

      if (item.status == "COMPLETED") {
        this.completedGiftCards.push(item);
        return;
      }
    });

    this.pageloading = false;
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
  },
};
</script>

<style scoped></style>

<style>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--mainColor);
}
.v-application a {
  color: black;
}
</style>
