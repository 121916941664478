<template>
  <div class="card shadow">
    <div>
      <img
        :src="'http://uploads.wtnloiey.easyflip.ng/' + item.giftCard"
        class="card-img"
        height="120"
        alt=""
      />
    </div>
    <div class="card-body px-2 py-2">
      <TextTitleDisplay title="Card Category" :content="item.cardCategory" />
      <TextTitleDisplay title="Card Type" :content="item.cardSubCategory" />
      <TextTitleDisplay
        title="Amount"
        :content="('&#8358;' + item.amount) | formartNumber"
      />
      <TextTitleDisplay title="Status" :content="item.status" />
      <div class="d-flex justify-content-end">
        <button
          @click="viewGiftCard(item)"
          type="button"
          class="btnd btn-success text-white small"
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          VIEW
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextTitleDisplay from "../../../components/texts/TextTitleDisplay.vue";
export default {
  name: "UsersGiftCardCard",
  components: { TextTitleDisplay },
  props: ["item"],
  methods: {
    viewGiftCard(val) {
      this.$emit("viewGiftCard", val);
    },
  },
};
</script>

<style>
.btnd.small {
  font-size: 0.79rem;
}
</style>
